var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Fragment } from "react";
export var reactAttrInitializer = "data-react-app";
var reactModelAttrInitializer = "data-react-app-model";
var reactAdditionalModelAttrInitializer = "data-react-html-props";
var getProps = function (str) {
    if ((str === null || str === void 0 ? void 0 : str.length) > 0) {
        try {
            return JSON.parse(str);
        }
        catch (error) {
            console.error("react render issue", str, error);
            return {};
        }
    }
    return {};
};
var getAdditionalProps = function (value) {
    if (value) {
        var valuesArray = value.split("`;");
        return valuesArray.reduce(function (prev, currentElement) {
            var _a;
            var currentValues = currentElement.split(":`");
            return __assign(__assign({}, prev), (_a = {}, _a[currentValues[0]] = currentValues[1], _a));
        }, {});
    }
    return {};
};
var attrsToRemove = {
    props: reactModelAttrInitializer,
    componentName: reactAttrInitializer,
    additionalProps: reactAdditionalModelAttrInitializer,
};
var getSingleComponentValues = function ($element, componentsMap) {
    var _a = Object.keys(attrsToRemove).reduce(function (prev, element) {
        var _a;
        var keyValue = attrsToRemove[element];
        var value = $element.getAttribute(keyValue);
        $element.removeAttribute(keyValue);
        return __assign(__assign({}, prev), (_a = {}, _a[element] = value, _a));
    }, {}), props = _a.props, componentName = _a.componentName, additionalProps = _a.additionalProps;
    return {
        name: componentName,
        renderAtAuthor: $element.getAttribute("data-render-author") !== "false",
        component: componentName ? componentsMap[componentName] : Fragment,
        props: __assign(__assign(__assign({}, getProps(props)), getAdditionalProps(additionalProps)), { children: $element.innerHTML }),
    };
};
export default getSingleComponentValues;
