import { CtaLink, InfoBanner, InputFooter, InputLabel, LoyaltyMembershipInfo, MembershipLevelDisplay, NotificationBlock, PageTitleBlock, PointsDisplay, ProductPerks, ProductTryNowButton, TextContainer, ToasterBlock, VariantSelector, } from "@jti/rip";
export default {
    notification: NotificationBlock,
    "page-title": PageTitleBlock,
    toaster: ToasterBlock,
    "text-container": TextContainer,
    "info-banner": InfoBanner,
    "product-teaser-try-now-button": ProductTryNowButton,
    "points-display": PointsDisplay,
    "loyalty-membership-info": LoyaltyMembershipInfo,
    "membership-level-display": MembershipLevelDisplay,
    "input-label": InputLabel,
    "input-footer": InputFooter,
    "product-perks": ProductPerks,
    "variant-selector": VariantSelector,
    "cta-link": CtaLink,
};
