import getSingleComponentValues, { reactAttrInitializer } from "./getSingleComponentValues";
import render from "./render";
var renderAllComponents = function (componentsMap, documentObject) {
    if (documentObject === void 0) { documentObject = window.document; }
    documentObject.querySelectorAll("[".concat(reactAttrInitializer, "]")).forEach(function (singleElement) {
        var _a = getSingleComponentValues(singleElement, componentsMap), component = _a.component, props = _a.props, renderAtAuthor = _a.renderAtAuthor;
        if (renderAtAuthor || !window.Granite) {
            render(singleElement, component, props);
        }
    });
};
export default renderAllComponents;
