var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { createRoot } from "react-dom/client";
var render = function (element, Component, props) {
    var elementToRender = (_jsx(_Fragment, { children: _jsx(Component, __assign({}, props)) }));
    var root = createRoot(element);
    root.render(elementToRender);
};
export default render;
